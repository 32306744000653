import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Impressum from "../components/Impressum";

const ImpressumPageTemplate = ({ image, title, main }) => {
  return (
    <div>
      <Impressum image={image} title={title} main={main} />
    </div>
  );
};

ImpressumPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  main: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ImpressumPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={"Code Academy Berlin Imprint data"}
      />

      <ImpressumPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        main={frontmatter.main}
      />
    </Layout>
  );
};

ImpressumPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ImpressumPage;

export const impressumPageQuery = graphql`
  query ImpressumPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        main {
          heading
          blurbs {
            title
            text
          }
        }
      }
    }
  }
`;
