import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
// import HTMLContent from "../Content";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
const Corona = ({ image, title, main, classes }) => {
  const theme = useTheme();
  return (
    <Container
      style={{
        paddingTop: theme.spacing(12),
        marginTop: theme.spacing(4),
        // display: "flex",
        // alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        // className={classes.links}
        gutterBottom
      >
        {main.heading}
      </Typography>

      {main.blurbs.map((item) => {
        return (
          <Container className={classes.container}>
            <Typography variant="h5" className={classes.boldText} gutterBottom>
              {item.title}
            </Typography>
            <Typography
              variant="p"
              // className={classes.links}
              gutterBottom
            >
              {item.text}
            </Typography>
          </Container>
        );
      })}
      {/* <HTMLContent className={classes.text} content={content} /> */}
    </Container>
  );
};

export default withStyles(styles)(Corona);
